<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Maintenance Urgencies ({{ totalItems }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'maintenance.urgency.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Urgency
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="urgency-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search urgency"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="urgencies"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @update:options="fetchUrgencies"
        @pagination="handleScrollAfterTableRender"
        :items-per-page.sync="itemsPerPageTables"
      >
        <template v-slot:item.updated_at="{ item }">
          {{ item.updatedDate }}
        </template>
        <template v-slot:item.added_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(urgencies.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Urgency list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'UrgenciesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'label',
      sortOrder: false,
      headers: [
        {
          text: 'Urgency',
          value: 'label',
        },
        {
          text: 'Updated date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      urgencies: (state) => state.urgency.list,
      listMeta: (state) => state.urgency.listMeta,
      paramsUrgency: (state) => state.urgency.queryParams,
      scrollPosition: (state) => state.urgency.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getUrgencies: 'urgency/getUrgencies',
    }),

    ...mapMutations({
      clearUrgencies: 'urgency/clearUrgencyList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'urgency/setQueryParams',
      setScrollPosition: 'urgency/setScrollPosition',
    }),

    filterUrgency(filterValue) {
      this.fetchUrgencies(null, filterValue)
    },

    fetchInitParams() {
      if (this.paramsUrgency?.filter) {
        this.defaultStatus = this.paramsUrgency.filter
      }

      if (this.paramsUrgency?.itemsPerPage) {
        const { itemsPerPage } = this.paramsUrgency
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsUrgency?.sort) {
        if (this.paramsUrgency.sort[0].includes('-')) {
          this.defaultFilter = this.paramsUrgency.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsUrgency?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'label'
      }

      if (this.paramsUrgency?.search) {
        this.filter.search = this.paramsUrgency.search
      }

      this.fetchUrgencies(null, 1, this.paramsUrgency)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchUrgencies(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetchUrgencies()
    }, 600),

    async fetchUrgencies(options, page = 1, paramsUrgency = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalItems
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalItems
          : this.itemsPerPageTables,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearUrgencies()
      this.loading = true
      await this.getUrgencies(paramsUrgency || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.urgency.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
